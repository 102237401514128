import {
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  Rating,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SensorDoorOutlinedIcon from "@mui/icons-material/SensorDoorOutlined";
import {
  Bottom,
  BottomLeft,
  BottomRight,
  Container,
  ContainerRight,
  PriceLeft,
  PriceRight,
  PriceRow,
  RightBottom,
  RightMid,
  RightPrice,
  RightTop,
  RightTopLeft,
  RightTopRight,
} from "./index.sty";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { getNoOfNight } from "../../utils";
import { LoginContext } from "../../Contexts/LoginContext";
import DateRoomsAdultCount from "../DateRoomsAdultCount";
import "./cardright.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F7F9F9",
  outline: "none",
  boxShadow: 24,
  p: 2,
};

const CardRight = (props) => {
  const { searchParams, userProfileDetails, rooms } = useContext(LoginContext);
  const newRooms = rooms;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [appliedCoupon, setAppliedCoupon] = useState(false);
  // const [selectedCoupon, setSelectedCoupons] = useState([]);

  // function getIntersectingDates(range1, range2) {
  //     const start1 = new Date(range1[0]);
  //     const end1 = new Date(range1[1]);
  //     const start2 = new Date(range2[0]);
  //     const end2 = new Date(range2[1]);

  //     if (start1 > end1 || start2 > end2) {
  //         return null;
  //     }

  //     const intersectionStart = start1 > start2 ? start1 : start2;
  //     const intersectionEnd = end1 < end2 ? end1 : end2;

  //     if (intersectionStart > intersectionEnd) {
  //         return null;
  //     }

  //     return [intersectionStart, intersectionEnd];
  // }

  const navigate = useNavigate();
  const {
    hotelData,
    roomsDetails,
    buttonMsg,
    setRoomDetails,
    appliedCoupon,
    selectedCoupon,
    handleApplied,
  } = props;
  // console.log(roomsDetails, "coming from asad");
  // console.log("..roomsDetails..", roomsDetails);
  // console.log("..selectedRoomDetails..", roomsDetails?.selectedRoomInfo);
  // console.log(
  //   "priceAndSelectedRoomDetails",
  //   roomsDetails?.priceAndSelectedRoomDetails
  // );
  // console.log(
  //   "mendatoryRoomServiceArray",
  //   roomsDetails?.mendatoryRoomServiceArray
  // );

  const handleCoupon = (code) => {
    // console.log(code,"code");
    // let res = rooms[0]?.coupons?.filter(coupon => coupon?.coupon_code === code)
    // console.log(res,"comming ffrom handle coupons function");
    // rooms[0]?.coupons?.forEach(element => {
    //   console.log(element.coupon_code);
    // });
  };
  console.log(moment(), moment().add(1, "days"), "moment");
  console.log(
    new Date(),
    new Date(new Date().valueOf() + 1000 * 3600 * 24),
    "without moment"
  );
  function onBookNowClick() {
    if (!userProfileDetails?.phone_number) {
      navigate("/login", {
        state: {
          data: {
            redirectPage: "hotelpage",
            searchParams,
            hotelData,
            roomsDetails,
          },
        },
      });
    } else {
      navigate("/booking", {
        state: { data: { searchParams, hotelData, roomsDetails } },
      });
    }
  }
  if (hotelData && roomsDetails && searchParams) {
    const { rooms, priceAndSelectedRoomDetails, selectedRoomInfo } =
      roomsDetails;
    // console.log("===check this immediately", priceAndSelectedRoomDetails);
    const calculateCouponsPrice = (discoutType) => {
      if (discoutType === "percentage") {
        let couponPrice =
          priceAndSelectedRoomDetails?.totalHotelSumBeforeDisCount *
          (selectedCoupon[0]?.coupon_discount / 100);

        console.log(couponPrice, "from function");
        return couponPrice;
      } else {
        let couponPrice = selectedCoupon[0]?.coupon_discount;
        return couponPrice;
      }
    };
    return (
      <Container>
        <ContainerRight>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center", m: 1 }}
              >
                Coupons for you
              </Typography>
              {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography> */}
              <List>
                {rooms?.[0]?.coupons?.map((coupon, index) => (
                  <div className="coupon-card-single">
                    <p>{coupon?.coupon_name}</p>
                    <p>{coupon?.coupon_code}</p>
                    <button
                      style={{
                        width: "20%",
                        borderRadius: "4px",
                        fontSize: "12px",
                        backgroundColor:
                          appliedCoupon &&
                          selectedCoupon[0]?.coupon_code === coupon?.coupon_code
                            ? "white"
                            : "#0d9488",
                        color:
                          appliedCoupon &&
                          selectedCoupon[0]?.coupon_code === coupon?.coupon_code
                            ? "#FF0000"
                            : "white",
                      }}
                      onClick={() => {
                        let res = rooms[0]?.coupons?.some(
                          (coupont) =>
                            coupont?.coupon_code === coupon?.coupon_code
                        );
                        let selec = rooms[0]?.coupons?.filter(
                          (coupont) =>
                            coupont?.coupon_code === coupon?.coupon_code
                        );
                        console.log(
                          selec,
                          "comming ffrom handle coupons function"
                        );
                        handleApplied(res, selec);
                        // setAppliedCoupon(res);
                        // setSelectedCoupons(selec);
                        handleClose();
                      }}
                    >
                      {appliedCoupon &&
                      selectedCoupon[0]?.coupon_code === coupon?.coupon_code
                        ? "Applied"
                        : "Apply"}
                    </button>
                  </div>
                ))}
              </List>
            </Box>
          </Modal>
          <RightTop>
            <RightTopLeft>
              <Typography style={{ fontWeight: "bold" }}>
                {hotelData?.hotelname}
              </Typography>
              <Typography style={{ color: "#e4e4e4" }}>
                {hotelData?.locality?.locality_name}
              </Typography>
              <div
                className="left-rating"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "10px 5px",
                }}
              >
                <div style={{ flex: "1 1 auto" }}>
                  <Button
                    variant="contained"
                    color="success"
                    endIcon={<StarIcon />}
                  >
                    {hotelData?.rating_json?.rating}
                  </Button>
                </div>
                <div style={{ flex: "1 1 auto", textAlign: "right" }}>
                  <p className="rating-words">
                    {hotelData?.rating_json?.rating_in_words}
                  </p>
                  <Rating
                    name="size-large"
                    size="large"
                    value={hotelData?.hotel_star}
                    readOnly
                  />
                </div>
              </div>
              <Typography style={{ fontWeight: "bold" }}>
                {getNoOfNight(searchParams?.from_date, searchParams?.to_date) ||
                  1}{" "}
                Night
              </Typography>
            </RightTopLeft>
            <RightTopRight>
              <img
                alt="img"
                width="100%"
                src={selectedRoomInfo?.rooms_types_gallery[0]?.file}
                style={{
                  marginBottom: "auto",
                  marginLeft: "auto",
                }}
              />
            </RightTopRight>
          </RightTop>
          <RightMid>
            <DateRoomsAdultCount setRoomDetails={setRoomDetails} />
            <hr style={{ margin: "1rem auto" }} />
            <Typography
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SensorDoorOutlinedIcon style={{ marginRight: "1rem" }} />{" "}
              {selectedRoomInfo?.roomtype}
            </Typography>
          </RightMid>
          <RightPrice>
            <PriceRow>
              <PriceLeft>
                <span>
                  {searchParams?.room_count} Room X{" "}
                  {getNoOfNight(
                    searchParams?.from_date,
                    searchParams?.to_date
                  ) || 1}{" "}
                  Night X{" "}
                  {searchParams?.no_of_adults + searchParams?.no_of_children}{" "}
                  Guests
                </span>
                {(priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price
                  ?.extra_adults_price > 0 ||
                  priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price
                    ?.child_price > 0) && (
                  <Tooltip
                    title={
                      <>
                        <div style={{ fontSize: "small" }}>
                          Price for extra adults/children
                        </div>
                        <div style={{ fontSize: "small" }}>
                          {priceAndSelectedRoomDetails?.allSelectedRooms[0]
                            ?.room_price?.extra_adults_price > 0 && (
                            <PriceRow>
                              <PriceLeft>Extra adult price </PriceLeft>
                              <PriceRight>
                                ₹
                                {
                                  priceAndSelectedRoomDetails
                                    ?.allSelectedRooms[0]?.room_price
                                    ?.extra_adults_price
                                }
                              </PriceRight>
                            </PriceRow>
                          )}
                          {priceAndSelectedRoomDetails?.allSelectedRooms[0]
                            ?.room_price?.child_price > 0 && (
                            <PriceRow>
                              <PriceLeft>Children price</PriceLeft>
                              <PriceRight>
                                ₹
                                {
                                  priceAndSelectedRoomDetails
                                    ?.allSelectedRooms[0]?.room_price
                                    ?.child_price
                                }
                              </PriceRight>
                            </PriceRow>
                          )}
                        </div>
                      </>
                    }
                    placement="right-start"
                    arrow
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {/* <span style={{ fontStyle: "italic", opacity: "0.5" }}>
                  {searchParams?.room_count} Room X{" "}
                  {getNoOfNight(
                    searchParams?.from_date,
                    searchParams?.to_date
                  ) || 1}{" "}
                  Night X {searchParams?.no_of_adults} Guests{" "}
                </span> */}
              </PriceLeft>
              <PriceRight>
                ₹
                {priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price
                  ?.price * searchParams?.room_count}
                {/* ₹{priceAndSelectedRoomDetails?.totalHotelSumBeforeDisCount} */}
              </PriceRight>
            </PriceRow>
            {priceAndSelectedRoomDetails?.allSelectedRooms[0]
              ?.room_price_date_wise?.length > 1 ? (
              <PriceRow>
                <PriceLeft>
                  Room price for Date
                  <Tooltip
                    title={
                      <>
                        {priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price_date_wise?.map(
                          (date) => {
                            return (
                              <PriceRow>
                                <PriceLeft>
                                  {/* {(new Date(date.start_date).toISOString())} to {date.end_date} */}
                                  {new Date(
                                    new Date(date?.start_date)?.getTime() +
                                      86400000
                                  )
                                    .toISOString()
                                    .slice(0, 10)}{" "}
                                  to{" "}
                                  {new Date(
                                    new Date(date?.end_date)?.getTime() +
                                      86400000
                                  )
                                    .toISOString()
                                    .slice(0, 10)}
                                </PriceLeft>
                                <PriceRight>₹{date?.price}</PriceRight>
                              </PriceRow>
                            );
                          }
                        )}
                      </>
                    }
                    placement="right-start"
                    arrow
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </PriceLeft>
              </PriceRow>
            ) : null}
            {priceAndSelectedRoomDetails?.mendatoryRoomServiceArray ? (
              priceAndSelectedRoomDetails?.mendatoryRoomServiceArray?.map(
                (mendatoryRoomServiceObj) => {
                  return (
                    <PriceRow>
                      <PriceLeft>
                        {mendatoryRoomServiceObj?.serviceName}{" "}
                        <span style={{ fontStyle: "italic", opacity: "0.5" }}>
                          {getNoOfNight(
                            searchParams?.from_date,
                            searchParams?.to_date
                          ) || 1}{" "}
                          Night
                        </span>
                      </PriceLeft>
                      <PriceRight>
                        +₹{mendatoryRoomServiceObj?.grossAmount}
                      </PriceRight>
                    </PriceRow>
                  );
                }
              )
            ) : (
              <></>
            )}
            {priceAndSelectedRoomDetails?.discountArray ? (
              priceAndSelectedRoomDetails?.discountArray?.map((discountObj) => {
                if (discountObj?.discountAmount > 0) {
                  return (
                    <PriceRow>
                      <PriceLeft>
                        <span>{discountObj?.discountName}</span>{" "}
                        <span style={{ fontStyle: "italic", opacity: "0.5" }}>
                          Discount
                        </span>
                      </PriceLeft>
                      <PriceRight>
                        -₹{discountObj?.discountAmount.toFixed(2)}
                      </PriceRight>
                    </PriceRow>
                  );
                }
              })
            ) : (
              <></>
            )}

            {/* {priceAndSelectedRoomDetails?.allSelectedRooms[0]?.no_of_adults >
              2 && ( */}
            {/* <PriceRow>
              <PriceLeft>Extra adult price</PriceLeft>
              <PriceRight>
                ₹
                {
                  priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price
                    ?.extra_adults_price
                }
              </PriceRight>
            </PriceRow> */}
            {/* )} */}
            {/* {priceAndSelectedRoomDetails.allSelectedRooms[0].room_price
              .child_price && ( */}
            {/* <PriceRow>
              <PriceLeft>Children price</PriceLeft>
              <PriceRight>
                ₹
                {
                  priceAndSelectedRoomDetails?.allSelectedRooms[0]?.room_price
                    ?.child_price
                }
              </PriceRight>
            </PriceRow> */}
            {/* )} */}
            {appliedCoupon ? (
              <>
                <PriceRow>
                  <PriceLeft>{selectedCoupon[0]?.coupon_code}</PriceLeft>
                  <PriceRight className="coupons-add-in-price">
                    ₹
                    {calculateCouponsPrice(
                      selectedCoupon[0]?.coupon_discount_type
                    )}
                  </PriceRight>
                </PriceRow>
                <PriceRow sx={{ justifyContent: "flex-end", mt: -1 }}>
                  <div>
                    <span
                      style={{ color: "#FF0000", cursor: "pointer" }}
                      onClick={() => handleApplied(false, [])}
                    >
                      remove
                    </span>
                  </div>
                </PriceRow>
              </>
            ) : (
              <PriceRow>
                <PriceLeft>Coupons</PriceLeft>
                <PriceRight
                  className="coupons-add-in-price"
                  onClick={handleOpen}
                >
                  Add
                </PriceRight>
              </PriceRow>
            )}

            <PriceRow>
              <PriceLeft>Taxes & Service Fees</PriceLeft>
              <PriceRight>
                ₹
                {(priceAndSelectedRoomDetails?.taxAndServiceObj?.totalTaxAmount).toFixed(
                  2
                )}
              </PriceRight>
            </PriceRow>
          </RightPrice>
          <hr style={{ width: "90%", margin: "2rem auto" }} />

          <RightBottom>
            <BottomLeft>
              <Typography style={{ fontSize: "2rem" }}>
                Total Amount to be paid
              </Typography>
            </BottomLeft>
            <BottomRight>
              ₹
              {(
                parseFloat(
                  priceAndSelectedRoomDetails?.totalHotelSumAfterDisCount *
                    searchParams?.room_count
                ) +
                priceAndSelectedRoomDetails?.taxAndServiceObj?.totalTaxAmount
              ).toFixed(2)}
            </BottomRight>
          </RightBottom>
          {buttonMsg !== "" ? (
            <Bottom
              style={{
                backgroundColor: "gray",
                color: "#fff",
                cursor: "pointer",
              }}
              disabled
            >
              {buttonMsg}
            </Bottom>
          ) : (
            <Bottom
              style={{
                backgroundColor: "#1ab64f",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => onBookNowClick()}
            >
              Continue to Book
            </Bottom>
          )}
          <Bottom>All staff vaccinated.</Bottom>
        </ContainerRight>
      </Container>
    );
  }
};
export default CardRight;
